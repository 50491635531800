.term-heading {
  font-size: 56px;
  width: 90%;
  margin: 8px auto;
  text-align: center;
}

.term-para {
  width: 90%;
  margin: auto;
}
.agree {
  text-align: start;
}
ul {
  text-align: start;
}
ul > li {
  font-weight: 700;
}
