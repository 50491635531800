.css-13cymwt-control {
  height: 46px;
  border-radius: 10px 10px 0px 0px !important;
}
.css-qbdosj-Input {
  color: #7877a0 !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1pwziu7-control {
  height: 46px !important;

  /* border-radius: 10px 10px 0px 0px !important; */
}

.dropdown-item:hover {
  background-color: rgb(211, 237, 252);
}

.react-select-dropdown {
  position: absolute;
}