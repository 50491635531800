* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 16px;
  }
}
body {
  margin: 0px;
  padding: 0px;
}
/* Font Classes */
.fs-9 {
  font-size: 0.5rem;
}
.fs-11 {
  font-size: 0.6875rem;
}
.fs-12 {
  font-size: 12px;
}
.fs-18 {
  font-size: 1.125rem !important;
}
.fs-36 {
  font-size: 2.25rem !important;
}

.fs-46 {
  font-size: 3rem !important;
}

.fs-32 {
  font-size: 2rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}
.fs-16 {
  font-size: 1rem !important;
}

.fs-26 {
  font-size: 1.625rem;
}
.fs-30 {
  font-size: 1.87rem;
}
fw-400 {
  font-weight: 400;
}
fw-500 {
  font-weight: 500;
}
fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700 !important;
}
fw-800 {
  font-weight: 800;
}
/* custom margin classes */

.m-20 {
  margin: 1.3rem;
}
.mt-15 {
  margin-top: 0.93rem;
}
.ml-8 {
  margin-left: 8px;
}

.mx-32 {
  margin: 2rem 0px;
}
.mx-12 {
  margin: 0px 0.8rem;
}
.mx-6 {
  margin: 0px 0.4rem;
}
.mt-12 {
  margin-top: 0.8rem;
}
.mt-20 {
  margin-top: 1.3rem;
}
.mt-24 {
  margin-top: 1.5em;
}
.mt-28 {
  margin-top: 1.75em;
}

.ml-12 {
  margin-left: 0.8em;
}
.ml-10 {
  margin-left: 0.625rem;
}
.ml-6 {
  margin-left: 0.4em;
}
.ml-16 {
  margin-left: 1rem;
}
.ml-30 {
  margin-left: 1.87rem !important;
}
.ml-20 {
  margin-left: 1.3rem;
}
.mr-24 {
  margin-right: 1.5em;
}
.mr-16 {
  margin-right: 1rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mb-10 {
  margin-bottom: 0.625rem;
}
.mb-12 {
  margin-bottom: 0.8rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-9 {
  margin-top: 0.5rem;
}
.mt-6 {
  margin-top: 0.4rem;
}
.mt-30 {
  margin-top: 1.87rem !important;
}
.mt-42 {
  margin-top: 2.625rem !important;
}
.mb-30 {
  margin-bottom: 1.87rem !important;
}
.mb-20 {
  margin-bottom: 1.25rem;
}
.mb-30 {
  margin-bottom: 2rem;
}

.mt-36 {
  margin-top: 2.25rem !important;
}
.mt-37 {
  margin-top: 2.26rem;
}

.mt-43 {
  margin-top: 2.688rem !important;
}
.mb-60 {
  margin-bottom: 3.7rem;
}
.mt-80 {
  margin-top: 5rem;
}
.mt-60 {
  margin-top: 3.7rem;
}
.mt-85 {
  margin-top: 5.13rem;
}
.mb-100 {
  margin-bottom: 10.25rem;
}
.mb-14 {
  margin-bottom: 0.875rem;
}
.mt-50 {
  margin-top: 3.15rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mx-34 {
  margin: 2.12rem 0px;
}
/* colors */

.grey {
  color: #b0b0c7;
}
.white {
  color: white;
}
/* .lightgray {
  color: #999999 !important;
} */
.lightgray {
  color: #acacc4;
}
hr {
  color: lightgray;
}
.darkblue {
  color: #19104e;
}
.blue {
  color: #19104e;
}
.bg {
  background-color: gray;
}
.black {
  color: black !important;
}
/* paddgin */
.p-16 {
  padding: 1rem;
}
.p-0 {
  padding: 0px;
}
.p-16 {
  padding: 1rem;
}
.p-34 {
  padding: 2.12rem;
}
.bg-white {
  background-color: white;
}
.px-124 {
  padding: 0px 7.75rem;
}
.fw-700 {
  font-weight: 700;
}
.fw-700 {
  font-weight: 700;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-400 {
  font-weight: 400;
}
.text-purple {
  color: #19104e;
}
.border-0 {
  border: 0px;
}
.list-style-none {
  list-style: none;
}
.cursor-pointer {
  cursor: pointer;
}
.fs-10 {
  font-size: 10px;
}
.fs-20 {
  font-size: 1.25rem;
}
.br-0 {
  border-radius: 0px !important;
}
.add-Members div {
  display: flex;
  justify-content: end;
}
.red {
  color: red !important;
}
.text-blue {
  color: #105caa;
}
.Register1 {
  width: 50%;
  height: 100%;
}
.Register2 {
  width: 50%;
  height: 100%;
}
.Registorform {
  width: 84%;
  margin: auto;
}
.otp-form {
  width: 70%;
  margin: auto;
}
.otp {
  width: 74%;
  margin: 37px auto;
}
.p-0 {
  padding: 0px;
}
.section {
  display: none;
  color: #105caa !important;
}
.section:hover {
  display: block;
  color: #105caa;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.btn-close {
  margin: 0px !important;
  position: absolute !important;
  right: 12px !important;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0; /* Optional: remove padding if desired */
  font-size: 12px;
}
#placeholder div {
  font-size: 14px;
}
.global-input::placeholder {
  color: rgba(120, 119, 160, 0.4);
  font-size: 14px !important;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.icon-hover {
  fill: #b2b2b2 !important;
  transition: fill 0.3s ease;
}
.icon-hover:hover {
  fill: #125caa; /* Color to change to on hover */
}
.tooltip-button {
  border: none;
  background: white;
}
.icon-hover {
  fill: green;
}
.modal-content {
  background-color: #fbfcff;
}

input[type="radio"] {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.textAreaBox {
  position: relative;
}
.textAreaBox p {
  position: absolute;
  top: 5px;
  left: 12px;
}
.css-1xc3v61-indicatorContainer {
  display: none !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.33333);
  border: 0.25em /* 4px / 16px */ solid rgb(218, 216, 216);
  border-radius: 1.25em /* 20px / 16px */;
}
.form-check-input {
  border: 2px solid #105caa !important;
}

.test-select svg:not(.search-box-icon) {
  display: none;
}
.test-select {
  position: relative;
}

.magnify-search-icon span {
  padding: 10px;
  background-color: #d8f1fb;
  border-radius: 4px;
  cursor: pointer;
}
.magnify-search-icon span:hover {
  background-color: #edf4f7;
  border-radius: 4px;
  cursor: pointer;
}
.css-13cymwt-control:focus {
  outline: none;
  border: none;
  background-color: yellow;
}
.header-otp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-otp-whatsApp {
  right: 10px;
  position: absolute;
}
.mobheader-otp-whatsApp {
  display: flex;
  position: absolute;
  align-items: center;
  background-color: #d4f1f4;
}
.bottom-right {
  width: 25% !important;
  position: absolute;
  top: 54%;
  left: 24%;
  font-weight: 700;
}
.otp-aboutUs:hover {
  text-decoration: underline;
}
.otp-about-whatsapp {
  position: absolute;
  right: 0px;
  color: #105caa;
}
.clearRx-video {
  margin: 0px;
  color: #7877a0;
  align-self: end;
  text-align: center;
  font-weight: 700;
}
.clearRx-video ins:hover {
  color: #105caa;
}
.clearRx-video ins {
  font-size: 14px;
  margin-left: 11px;
}
.input-group {
  border: 3px solid #cddef1;
  border-radius: 12px;
}
/* input[inputmode="numeric"] {
  flex-wrap: nowrap !important;
} */
