.health-care {
  color: #105caa;
  text-align: center;
  font-family: EB Garamond;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
}
.navbar-center li {
  cursor: pointer;
}
.nav-menu {
  position: relative;
}

.nav-menu:hover::after,
.nav-menu.active::after {
  margin: 0 auto;
  content: "";
  display: block;
  width: 13px;
  height: 4px;
  background: #105caa;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.avatar {
  background: #d8f1fb;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}
.right-nav-item {
  display: flex;
  gap: 12px;
}
#dropdown-menu-align-end {
  background-color: #d8f1fb !important;
  color: #6a6b6b;
  font-weight: 600;
  border: 2px solid #d8f1fb !important;
}
.dropdown-menu {
  width: 230px;
}
