.upload-certificate-parent {
  display: flex;
  align-items: flex-start;
  gap: 4;
}
.upload-certificate {
  display: flex;
  padding: 19px 45px;
  justify-content: center;
  align-self: center;
  align-items: center;
  /* width: 40%; */
  border: 1px dashed rgba(25, 16, 78, 0.1);
  position: relative;
  bottom: 23px;
}
.upload {
  display: flex;
  /* padding: 14px; */
  width: 50%;
  justify-content: start;
  width: 100% !important;
  min-height: 157px;

  border: 1px dashed rgba(25, 16, 78, 0.1);
  overflow: hidden;
}
.upload-child {
  max-height: 120px !important;
  overflow: scroll !important;
}

.label {
  font-size: 11px;
}
.icon {
  background-color: #64cdfa;
  margin-right: 14px;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.pdf-icon {
  margin: 12px;
  padding: 4px;
  max-width: 50px !important;
  flex-direction: column;
  word-wrap: break-word;
}

.pdf-icon .upload-croos-icon {
  position: relative;
  bottom: 40px;
  left: 36px;
}
.upload-more {
  border-bottom: 1px solid #2a225b;
  cursor: pointer;
}
.upload-section {
  width: 50%;
  flex-direction: column;
}
.uploaded-certificate {
}
