body {
  margin: 0;
  /* font-family: "-apple-system ", "Segoe UI",  "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif,
    "BlinkMacSystemFont"; */
  font-family: "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

mt-20 {
  margin: 20px;
}



  .custom-gap {
    gap: 3rem; 
  }

  @media (max-width: 576px) {
    .custom-gap {
      gap: 1rem; 
    }
  }