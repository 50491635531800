.back-icon-parent {
  display: flex;
  width: 30px;
  justify-content: center;
  height: 30px;
  align-items: center;
  border: 1px solid #eceef3;
  border-radius: 6px;
}
.header-center {
  color: #19104e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 148%; /* 35.52px */
}
.header-left {
  color: #19104e;
  font-size: 14px;
  font-weight: 500px;
  background-color: #d8f1fb;
  padding: 6px 11px;
  border-radius: 6px;
}
