.description {
  color: #19104e;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.subdescription {
  color: #7877a0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subdescription a {
  color: #3db8ed;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
