.doctor-info-card {
  padding: 27px 20px;
  display: flex;
  background-color: #105caa !important;
  border: none !important;
}
.card {
  flex-direction: row !important;
}
.doctor-detail {
  display: flex;
  flex-direction: column !important;
  margin-left: 1.125rem;
}
