.RxTabs {
  border: none !important;
}
.RxTabs-title {
  color: #7877a0 !important;
}
.RxTabs-icon {
  padding: 6px;
  border-radius: 50%;
  border: 2px solid #105caa !important;
}
