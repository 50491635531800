.fixed-tab {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background: "white";
}
.mobiletabhover {
  border-radius: 20px;
  padding: 2px 13px;
  font-weight: 700;
}
.mobiletabhover12 {
  display: flex;
  justify-content: center;
  border-radius: 12px;
  color: #105caa;
}
.navlinkColor {
  border-radius: 20px;
  background-color: #d8f1fb !important;
  justify-content: center;
  color: "#105CAA" !important;
}
.navlinkColor.active .icon-hover {
  fill: green;
}
.mobile-text {
  color: #145dab !important;
}
